const cals = {
  tableData: [{
    data: [],
  }],
  _self: null,
  handlePromotionInfo: null,
  checkPromotion: [],
  promotionDict: {
    multipleable: [],
    firstOrder: [],
    promotionType: [],
    composeState: [],
    company: [],
    unit: [],
    unitType: [],
  },
  // 数据深拷贝
  copyData(data) {
    return JSON.parse(JSON.stringify(data));
  },
  // 判断当前选择政策是否有买赠
  handleBuyGifts(list, handleIndex) {
    let hasBuyGift = null;
    let {
      tableIndex,
    } = handleIndex;
    const {
      productIndex,
    } = handleIndex;
    const promotionList = [];
    list.forEach((v) => {
      if (v.checked) {
        promotionList.push(v);
        if (v.promotionType === '0') {
          hasBuyGift = v;
        }
      }
    });
    const product = this.copyData(this.tableData[tableIndex].data[productIndex]);
    product.promotionList = promotionList;
    this.handlePromotionInfo = null;
    // 判断是否有选择政策
    if (promotionList && promotionList.length > 0) {
      // 如果有买赠活动
      if (hasBuyGift) {
        this.handlePromotionInfo = hasBuyGift;
        // 判断是否有相同的买赠活动
        const sameIndex = this.handleSamePromotion(hasBuyGift);

        // 没有相同的买赠政策
        if (typeof sameIndex !== 'number') {
          // 获取赠品数据
          const giftData = this.getGiftData(hasBuyGift);

          this.tableData.unshift({
            key: hasBuyGift.promotionCode,
            promotionCode: hasBuyGift.promotionCode,
            promotionName: hasBuyGift.promotionName,
            promotionPolicyId: hasBuyGift.promotionPolicyId,
            promotionType: hasBuyGift.promotionType,
            data: [
              product,
              ...giftData,
            ],
          });
          tableIndex += 1;
          this.tableData[tableIndex].data.splice(productIndex, 1);
        } else if (typeof sameIndex === 'number') {
          // 当前商品买赠政策没有发生变化，直接在当前数据上修改状态
          if (sameIndex === tableIndex) {
            this.tableData[tableIndex].data[productIndex] = product;
          } else {
            // 在相同的政策中判断时候已有该商品
            const hasProduct = this.handlePromorionProduct(sameIndex, product);

            // 没有该商品在政策中，将该商品添加进去
            if (typeof hasProduct !== 'number') {
              this.tableData[sameIndex].data.unshift(product);
            }

            // 删除原政策中的该商品
            this.tableData[tableIndex].data.splice(productIndex, 1);
          }
        }
        // 判断当前政策中是否还有商品
        this.handlePromotionProduct(tableIndex);
      } else if (!hasBuyGift) {
        // 没有选中买赠政策，判断是否在本品列表上
        if (this.tableData[tableIndex].key) {
          this.setProductList(product, tableIndex, productIndex);
        } else {
          // 在本品列表直接在当前数据上修改状态
          this.tableData[tableIndex].data[productIndex] = product;
        }
      }
    } else if (promotionList && promotionList.length === 0) {
      // 没有选中政策下，判断是否在本品列表上
      if (this.tableData[tableIndex].key) {
        this.setProductList(product, tableIndex, productIndex);
      } else {
        // 在本品列表直接在当前数据上修改状态
        this.tableData[tableIndex].data[productIndex] = product;
      }
    }
    return this.tableData;
  },
  // 将商品放回本品列表
  setProductList(product, tableIndex, productIndex) {
    this.handlePromotionInfo = null;
    // 将该商品放回本品列表
    const hasProductTable = this.handleProductTable();
    if (hasProductTable) {
      // 有本品列表将该商品添加进去
      this.tableData[this.tableData.length - 1].data.unshift(product);
    } else {
      // 没有本品列表新增一个
      this.tableData.push({
        data: [product],
      });
    }
    this.tableData[tableIndex].data.splice(productIndex, 1);

    // 判断当前政策中是否还有商品
    this.handlePromotionProduct(tableIndex);
  },
  // 获取政策中赠品数据
  getGiftData(hasBuyGift) {
    const list = [];
    hasBuyGift.table_config.table_data.forEach((v) => {
      if (v.lineType === '1') {
        list.push(v);
      }
    });
    return list;
  },
  // 判断是否有相同的买赠活动
  handleSamePromotion(hasBuyGift) {
    let sameIndex = false;
    this.tableData.forEach((v, k) => {
      if (v.key === hasBuyGift.promotionCode) {
        sameIndex = k;
      }
    });
    return sameIndex;
  },
  // 在相同的政策中判断时候已有该商品
  handlePromorionProduct(sameIndex, product) {
    let hasProduct = false;
    this.tableData[sameIndex].data.forEach((v, k) => {
      if (v.productCode === product.productCode && v.lineType === product.lineType) {
        hasProduct = k;
      }
    });
    return hasProduct;
  },
  // 删除商品
  deleteProduct(tableIndex, productIndex) {
    this.tableData[tableIndex].data.splice(productIndex, 1);
    // 判断当前政策中是否还有商品
    this.handlePromotionProduct(tableIndex);
    // 判断最终总体列表是否还有商品
    return this.tableData;
  },
  // 删除政策
  deletePromotion(row, tableIndex, productIndex, promotionIndex) {
    this.handlePromotionInfo = null;
    const promotion = row.promotionList[promotionIndex];

    const promotionList = this.copyData(row.promotionList);
    const product = this.copyData(this.tableData[tableIndex].data[productIndex]);

    promotionList.splice(promotionIndex, 1);
    product.promotionList = promotionList;
    this.checkPromotion = promotionList;
    // 判断当前删除政策是否是买赠活动
    if (promotion.promotionType === '0') {
      this.setProductList(product, tableIndex, productIndex);
    } else {
      // 不是买赠活动直接在当前商品上修改状态
      this.tableData[tableIndex].data[productIndex] = product;
    }
    // 判断当前政策中是否还有商品
    this.handlePromotionProduct(tableIndex);

    return this.tableData;
  },
  // 判断当前政策中是否还有商品
  handlePromotionProduct(tableIndex) {
    let hasProduct = false;
    this.tableData[tableIndex].data.forEach((v) => {
      if (v.lineType === '0' || v.lineType === '2') {
        hasProduct = true;
      }
    });
    // 当前政策中没有本品以后，删除当前政策
    if (!hasProduct) {
      this.tableData.splice(tableIndex, 1);
    }
  },
  // 判断是否还有本品列表
  handleProductTable() {
    let hasProductTable = false;
    this.tableData.forEach((v) => {
      if (!v.key) {
        hasProductTable = true;
      }
    });
    return hasProductTable;
  },
  // 添加商品
  addProduct(val, modelType) {
    this.handlePromotionInfo = null;
    let list = [];
    if (modelType === '2') {
      list = this.addBackList(val);
    } else {
      list = this.addNormalList(val);
    }
    // 将该商品放回本品列表
    const hasProductTable = this.handleProductTable();
    if (hasProductTable) {
      // 有本品列表将该商品添加进去
      // 先判断是否有相同的商品
      const newData = [];
      list.forEach((v) => {
        let isSet = false;
        this.tableData[this.tableData.length - 1].data.forEach((a) => {
          if (a.productCode === v.productCode && a.lineType === v.lineType && a.feeCode === v.feeCode && a.promotionList.length === 0) {
            isSet = true;
          }
        });
        if (!isSet) {
          newData.push(v);
        }
      });
      this.tableData[this.tableData.length - 1].data = newData.concat(this.tableData[this.tableData.length - 1].data);
    } else {
      // 没有本品列表新增一个
      this.tableData.push({
        data: list,
      });
    }
  },
  // 添加本品处理逻辑
  addNormalList(val) {
    val.map((a) => {
      const row = a;
      row.lineType = '0';
      row.productNum = row.productNum || 1;
      // row.onlyKey = timestamp + row.productCode + row.lineType;
      return row;
    });
    return val;
  },
  // 添加货补商品
  addBackList(val) {
    const list = [];
    val.forEach((v) => {
      if (v.lineType === '2') {
        list.push({
          ...v,
          productNum: v.productNum || 1,
        });
      }
      if (v.children && v.children.length > 0) {
        v.children.forEach((a) => {
          if (a.lineType === '2') {
            list.push({
              ...a,
              productNum: a.productNum || 1,
            });
          }
        });
      }
    });
    return list;
  },
  // 获取滚动距离
  getScrollTop() {
    if (this.handlePromotionInfo) {
      return this._self.$refs[this.handlePromotionInfo.promotionCode][0].offsetTop;
    }
    if (this._self.$refs.productList[0]) {
      return this._self.$refs.productList[0].offsetTop;
    }
    return null;
  },
  // 初始化政策列表
  analysisPromotion(promotions) {
    const list = [];
    promotions.forEach((v) => {
      let checked = false;
      // 判断是否选中
      if (this.checkPromotion) {
        this.checkPromotion.forEach((a) => {
          if (a.promotionCode === v.promotionPolicyCode) {
            checked = true;
          }
        });
      }
      // 解析规则展示信息
      const ruleInfo = this.analysisRule(v);
      const tableData = [];
      // 本品信息初始化
      if (v.productMap.currentProducts) {
        v.productMap.currentProducts.forEach((a) => {
          tableData.push({
            ...a,
            lineType: '0',
            lineTypeName: '本品',
            totalProductOrderQty: v.productsOrderQty || '--',
          });
        });
      }
      // 赠品信息初始化
      if (v.productMap.giftProducts) {
        v.productMap.giftProducts.forEach((a) => {
          tableData.push({
            ...a,
            lineType: '1',
            lineTypeName: '赠品',
            // onlyKey: a.productCode + 1,
            productOrderQty: '--',
            productNum: '',
            totalProductOrderQty: v.productsOrderQty || '--',
          });
        });
      }

      list.push({
        checked,
        promotionPolicyId: v.id,
        promotionType: v.promotionType,
        promotionCode: v.promotionPolicyCode,
        promotionName: v.promotionPolicyName,
        rangeDate: `${v.beginTime}~${v.endTime}`,
        firstOrder: v.firstOrder,
        multipleable: v.multipleable,
        composeState: v.composeState ? 'Y' : 'N',
        productsOrderQty: v.productsOrderQty,
        rule_config: {
          thead: [{
            label: '促销描述',
            prop: 'remarks',
          },
          {
            label: '促销规则',
            prop: 'ruleInfo',
            columnKey: 'rule',
          },
          ],
          checkbox: false, // 是否显示多选
          table_data: [{
            ruleInfo,
            remarks: v.description || '--',
          }],
          isBorder: true, // 是否需要边框线
        },
        table_config: {
          thead: [{
            label: '商品类型',
            prop: 'lineTypeName',
          },
          {
            label: '商品名称',
            prop: 'productName',
          },
          {
            label: '起订量',
            prop: 'productOrderQty',
          },
          {
            label: '组合起订量',
            prop: 'totalProductOrderQty',
          },
          ],
          checkbox: false, // 是否显示多选
          table_data: tableData,
          isBorder: true, // 是否需要边框线
        },
      });
    });
    return list;
  },
  // 解析数据字典
  analysisField(data, field) {
    if (field === 'promotionType') {
      const name = this.promotionDict.promotionType.find((v) => v.dictCode === data);
      return name ? name.dictValue : data;
    }
    if (field === 'multipleable') {
      const name = this.promotionDict.multipleable.find((v) => v.dictCode === data);
      return name ? name.dictValue : data;
    }
    if (field === 'firstOrder') {
      const name = this.promotionDict.firstOrder.find((v) => v.dictCode === data);
      return name ? name.dictValue : data;
    }
    if (field === 'composeState') {
      const name = this.promotionDict.composeState.find((v) => v.dictCode === data);
      return name ? name.dictValue : data;
    }
    if (field === 'company') {
      const name = this.promotionDict.company.find((v) => v.dictCode === data);
      return name ? name.dictValue : data;
    }
    if (field === 'unit') {
      const name = this.promotionDict.unit.find((v) => v.dictCode === data);
      return name ? name.dictValue : data;
    }
    if (field === 'unitType') {
      const name = this.promotionDict.unitType.find((v) => v.dictCode === data);
      return name ? name.dictValue : data;
    }
  },
  // 政策选择变更
  changePromotion(promotionList, index) {
    const promotion = this.copyData(promotionList)[index];
    let hasConfirm = false;
    if (!this.checkPromotion) {
      this.checkPromotion = [];
    }
    // 判断是选中或者删除
    if (promotion.checked) {
      this.checkPromotion = this.checkPromotion.filter((v) => v.promotionCode !== promotion
        .promotionCode);
    } else {
      // 先判断和之前选择的政策是否有同类型的，或者之前的政策是否可叠加
      let sameType = false;
      let sameIndex = 0;
      let notMultipleable = false;
      this.checkPromotion.forEach((v, k) => {
        if (v.multipleable === 'N') {
          notMultipleable = true;
        }
        if (v.promotionType === promotion.promotionType) {
          sameType = true;
          sameIndex = k;
        }
      });
      // 已选政策可叠加，当前选择政策可叠加并且没有同类政策
      if (!notMultipleable && !sameType && promotion.multipleable === 'Y') {
        this.checkPromotion.push(promotion);
      }
      // 有同类政策
      if (sameType) {
        hasConfirm = {
          type: 'sameType',
          sameIndex,
          promotion,
          promotionList,
        };
      }
      // 以前的政策不可叠加，或者当前的政策不可叠加
      if (notMultipleable || promotion.multipleable === 'N') {
        if (this.checkPromotion.length > 0) {
          hasConfirm = {
            type: 'notMultipleable',
            promotion,
            promotionList,
          };
        } else {
          this.checkPromotion = [promotion];
        }
      }
    }
    const result = hasConfirm || {
      list: this.initPromotionList(promotionList),
    };
    return result;
  },
  // 同类政策变更
  changeSameType(result) {
    this.checkPromotion.splice(result.sameIndex, 1);
    this.checkPromotion.push(result.promotion);
    return this.initPromotionList(result.promotionList);
  },
  // 不可叠加政策变更
  changeMultipleable(result) {
    this.checkPromotion = [result.promotion];
    return this.initPromotionList(result.promotionList);
  },
  // 对列表中的选择状态重新赋值
  initPromotionList(promotionList) {
    const list = [];
    // 对列表中的选择状态重新赋值
    promotionList.forEach((v) => {
      let checked = false;
      this.checkPromotion.forEach((a) => {
        if (a.promotionCode === v.promotionCode) {
          checked = true;
        }
      });
      list.push({
        ...v,
        checked,
      });
    });
    return list;
  },
  // 解析规则
  analysisRule(data) {
    const rule = [];
    data.ruleMap.conditions.forEach((v) => {
      v.controlRows.forEach((a) => {
        const info = [a.text];
        a.controls.forEach((b) => {
          if (b.key !== 'buyUnit') {
            info.push(`${b.label + b.value}`);
          } else {
            const unitType = this.analysisField(b.value, 'unitType');
            info.push(unitType);
          }
        });
        rule.push(info.join(''));
      });
    });
    return rule;
  },
  // 组装提交算价数据
  handleSubmitCal() {
    const groupItemVos = [];
    this.tableData.forEach((v) => {
      // 判断当前列表是否是本品列表
      if (!v.key) {
        const {
          normalList,
          giftList,
          backList,
        } = this.handleProductCal(v);
        groupItemVos.push({
          normalList,
          giftList,
          backList,
          buyAndGiveFlag: 'N',
        });
      } else {
        const {
          normalList,
          giftList,
          backList,
        } = this.handlePromotionCal(v);
        groupItemVos.push({
          normalList,
          giftList,
          backList,
          promotionCode: v.promotionCode,
          promotionName: v.promotionName,
          promotionPolicyId: v.promotionPolicyId,
          promotionType: v.promotionType,
          buyAndGiveFlag: 'Y',
        });
      }
    });
    return groupItemVos;
  },
  // 本品列表处理逻辑
  handleProductCal(list) {
    const normalList = [];
    const giftList = [];
    const backList = [];
    list.data.forEach((a) => {
      if (a.lineType === '0') {
        // 本品
        // 处理需要的政策数据
        const promotionVos = [];
        if (a.promotionList && a.promotionList.length > 0) {
          a.promotionList.forEach((b) => {
            promotionVos.push({
              promotionCode: b.promotionCode,
              promotionName: b.promotionName,
              promotionPolicyId: b.promotionPolicyId,
              promotionType: b.promotionType,
              combinationFlag: b.composeState || b.combinationFlag,
            });
          });
        }
        normalList.push({
          // ext1: a.onlyKey,
          productCode: a.productCode,
          productName: a.productName,
          productNum: a.productNum || 1,
          imgUrl: a.imgUrl,
          promotionVos,
        });
      }
      if (a.lineType === '2') {
        // 货补
        backList.push({
          feeCode: a.feeCode,
          productCode: a.productCode,
          productName: a.productName,
          productNum: a.productNum || 1,
          imgUrl: a.imgUrl,
        });
      }
    });
    return {
      normalList,
      giftList,
      backList,
    };
  },
  // 买赠政策列表处理逻辑
  handlePromotionCal(list) {
    const normalList = [];
    const giftList = [];
    const backList = [];
    list.data.forEach((a) => {
      if (a.lineType === '0') {
        // 本品
        // 处理需要的政策数据
        const promotionVos = [];
        if (a.promotionList && a.promotionList.length > 0) {
          a.promotionList.forEach((b) => {
            promotionVos.push({
              promotionCode: b.promotionCode,
              promotionName: b.promotionName,
              promotionPolicyId: b.promotionPolicyId,
              promotionType: b.promotionType,
              combinationFlag: b.composeState || b.combinationFlag,
            });
          });
        }
        normalList.push({
          // ext1: a.onlyKey,
          productCode: a.productCode,
          productName: a.productName,
          productNum: a.productNum || 1,
          imgUrl: a.imgUrl,
          promotionVos,
        });
      }
      if (a.lineType === '1') {
        giftList.push({
          // ext1: a.onlyKey,
          productCode: a.productCode,
          productName: a.productName,
          productNum: a.productNum || '',
          imgUrl: a.imgUrl,
        });
      }
    });
    return {
      normalList,
      giftList,
      backList,
    };
  },
  // 解析返回的数据
  handleCalData(result) {
    const tableData = [];
    result.forEach((v, k) => {
      // 如果返回的数据的顺序和前端的是一样，直接根据下标去匹配商品然后赋值
      const data = [];
      v.normalList.forEach((a) => {
        data.push({
          ...a,
          lineType: a.lineType || '0',
          promotionList: a.promotionVos,
          imgUrl: a.imgUrl,
          saleUnit: a.salesUnit ? a.salesUnit : '',
          saleUnitName: this.analysisField(a.salesUnit ? a.salesUnit : '', 'unit'),
        });
      });
      v.giftList.forEach((a) => {
        data.push({
          ...a,
          lineType: a.lineType || '1',
          promotionList: [],
          imgUrl: a.imgUrl,
          saleUnit: a.salesUnit ? a.salesUnit : '',
          saleUnitName: this.analysisField(a.salesUnit ? a.salesUnit : '', 'unit'),
        });
      });
      v.backList.forEach((a) => {
        data.push({
          ...a,
          lineType: a.lineType || '2',
          promotionList: [],
          imgUrl: a.imgUrl,
          saleUnit: a.salesUnit ? a.salesUnit : '',
          saleUnitName: this.analysisField(a.salesUnit ? a.salesUnit : '', 'unit'),
        });
      });

      // 判断是否是本品列表
      if (v.buyAndGiveFlag === 'N') {
        tableData.push({
          key: '',
          data,
        });
      } else {
        tableData.push({
          key: v.promotionCode,
          calSucessMsg: v.calSucessMsg,
          promotionCode: v.promotionCode,
          promotionName: v.promotionName,
          promotionPolicyId: v.promotionPolicyId,
          promotionType: 0,
          data,
        });
      }
    });

    this.tableData = tableData;
    return tableData;
  },
  // 解析货补列表的数据
  analysisGoods(tableData) {
    const list = [];
    tableData.forEach((v) => {
      const children = [];
      v.productList.forEach((a) => {
        children.push({
          ...a,
          feeCode: v.poolCode,
          onlyCode: v.poolCode + a.productCode,
          promotionList: [],
          imgUrl: a.mainPictureUrl,
          productNum: a.productNum || 1,
          lineType: '2',
        });
      });
      list.push({
        ...v,
        onlyCode: v.poolCode,
        // productCode: v.poolCode,
        children,
      });
    });
    return list;
  },
  // 解析购物车数据
  analysisCar(tableData) {
    const list = [];
    for (const i in tableData) {
      if (tableData[i]) {
        tableData[i].forEach((v) => {
          if (v.goodsInfo) {
            list.push({
              salesCompanyCode: v.salesCompanyCode,
              salesCompanyName: this.analysisField(v.salesCompanyCode, 'company'),
              productCode: v.goodsInfo ? v.goodsInfo.productCode : '',
              productName: v.goodsInfo ? v.goodsInfo.productName : '',
              spec: v.goodsInfo ? v.goodsInfo.spec : '',
              saleUnit: v.goodsInfo ? v.goodsInfo.saleUnit : '',
              saleUnitName: this.analysisField(v.goodsInfo ? v.goodsInfo.saleUnit : '', 'unit'),
            });
          }
        });
      }
    }
    return list;
  },
};

export default cals;

/**
 *写一下function的逻辑吧
 *因为这套js需要作用3个端，分别是小程序，商城和管理系统，因为页面布局已经可能的架构不同，所以，逻辑尽量用原生js来处理，完了以后放回给页面
 *这里只对商品数据的列表做处理，包括列表的展示以及政策的选择逻辑
 *
 * 基本数据
 *
 *  tableData: 这个是这个js文件最核心的数组，每次获取了列表的变更都需要同步这个数据，逻辑也是根据这个数组去判断赋值等等
 *_self: 这个的作用是在vue的情况下，通过将页面的this传出来，方便调用页面的this.$refs来获取高度并实现滚动
 *handlePromotionInfo: 如上所述，获取高度的时候，需要一个对应的dom对象的高度
 *checkPromotion: 这个的意义在于，选择政策的时候，我需要根据已选的政策判断新选择的政策是否冲突或者同类等等，意义不大，要改的话可以获取
 *当前商品上面的promotionList
 *promotionDict: { 这个是数据字典，在页面加载的时候先获取一次，然后获取渲染页面的时候去解析
 *  multipleable: [],  是否可叠加
 *  firstOrder: [],   是否首单
 *  promotionType: [],  活动类型
 *  composeState: [],  是否组合计算
 *}
 *
 *调用的逻辑
 *log：每次变更列表的时候都是先用本地数据去校验组装数据，然后再解析本地数据去上传，如果应该先解析成接口需要的结构再判断可能更好，有空的自己换吧
 *log2:好像也还好，万一后台接口变了我只需要改提交和解析的逻辑，页面的逻辑是自己定义的
 *
 *每次添加本品或者货补的时候先执行一下
 *val.map((a) => {
 *       const imgUrl = a.pictureList ? JSON.parse(a.pictureList) : [];
 *       const row = a;
 *      row.imgUrl = imgUrl[0] ? imgUrl[0].urlAddress : '';
 *     row.lineType = '0';
 *    row.productNum = 1;
 *   return row;
 *});
 *
 * lineType为0表示本品，1是赠品，3是货补，在后续的提交算价的时候需要用它判断提交时候在哪个数组中，已经在因关联政策变更时判断一些数据结构的逻辑
 * imgUrl是图片，看具体接口吧，有些接口是没有的
 * productNum自己设置，默认1
 *
 *
 * 初始化的时候记得给tableData没有数据的情况下赋值 cals.tableData =  [{key:'' ,data: val }];
 *
 * 在匹配买赠的列表的时候需要用key去匹配相同的政策
 *
 *
 *最复杂的政策逻辑
 *
 *每次选择政策的时候需要判断是否是同类或者是不可叠加的changePromotion如果是取消政策那么没啥逻辑，如果是选中的话，就获取已选政策中是否存在同类的
 *以及当前选中政策是否可叠加，不用判断已选的政策时候可叠加，出现以上两种情况的话返回一个状态类型，让页面去出现弹框确认是否删除，在弹框的回调中
 * 调用changeSameType和changeMultipleable处理对应的逻辑，不需要弹框的可以把状态那里的注释了直接调用，然后统一返回处理的政策状态的数据
 *
 * 政策选择完毕就是确认的逻辑了，确认了以后需要对页面的数据去进行重组，如果最终的结果是没有买赠政策，那么说明该商品要放回本来列表中，
 * 如果选择了买赠政策，先判断列表数据中是否已经有了改政策，没有就添加列表数据，有的话就去对应的买赠政策中判断有没有该商品，有的不管了，没有就添加
 * 当商品数据出现更换列表的情况时，删除该商品原本的数据，再判断原商品数据的政策或者本品列表中是否还有商品，没有的话要删除政策列表或者本品列表，
 * 所以我们点击选择政策的弹框是，要在页面上用变量保存当前商品所处的政策下标和商品下标，在删除数据时候减少对数据的循环过程，有bug的话自己改
 *
 *
 * 说一下政策的数据处理
 *
 * checked表示当前政策是否选中，根据政策列表和checkPromotion的双循环来设置
 * promotionPolicyId，promotionType，promotionPolicyCode，promotionPolicyName这几个是在提交的时候需要用，需要转一下，项目上的自己看情况添加
 * 或者转义字段
 * rangeDate这个是时间，firstOrder这个是首单，multipleable这个是是否叠加，composeState这个是否组合计算，productsOrderQty这个是组合起订量
 * rule_config是规则列表里面的配置信息
 * table_config是商品的信息，所以页面在展示的时候根据需求去变更这两个config，
 * log：友情提示，productsOrderQty和productsOrderQty这个字段是不一样的不要取错了，多一个s的是组合起订量的字段，没有的是本品起订量
 *
 *
 *
 *
 *
 *未完待续！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！
 *
 *
 *
 *
 *
 * */
