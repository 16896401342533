var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "replenorderinfo" },
    _vm._l(_vm.promotionList, function (item, index) {
      return _c("table", { key: index, staticClass: "reptable" }, [
        _c("tr", [
          _c(
            "th",
            { staticClass: "checkeds" },
            [
              _c("el-checkbox", {
                attrs: { value: item.checked },
                on: {
                  change: function ($event) {
                    return _vm.checkPromotion(index)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "th",
            {
              on: {
                click: function ($event) {
                  return _vm.checkPromotion(index)
                },
              },
            },
            [_vm._v("政策名称")]
          ),
          _c(
            "th",
            {
              on: {
                click: function ($event) {
                  return _vm.checkPromotion(index)
                },
              },
            },
            [_vm._v("政策类型")]
          ),
          _c(
            "th",
            {
              on: {
                click: function ($event) {
                  return _vm.checkPromotion(index)
                },
              },
            },
            [_vm._v("是否叠加")]
          ),
          _c(
            "th",
            {
              on: {
                click: function ($event) {
                  return _vm.checkPromotion(index)
                },
              },
            },
            [_vm._v("是否首单")]
          ),
          _c(
            "th",
            {
              on: {
                click: function ($event) {
                  return _vm.checkPromotion(index)
                },
              },
            },
            [_vm._v("是否组合")]
          ),
          _c(
            "th",
            {
              staticStyle: { width: "300px" },
              on: {
                click: function ($event) {
                  return _vm.checkPromotion(index)
                },
              },
            },
            [_vm._v("有效期")]
          ),
        ]),
        _c(
          "tr",
          {
            staticClass: "cont_td",
            on: {
              click: function ($event) {
                return _vm.checkPromotion(index)
              },
            },
          },
          [
            _c("td", { attrs: { rowspan: "4" } }),
            _c("td", [_vm._v(_vm._s(item.promotionName))]),
            _c("td", [
              _vm._v(
                _vm._s(_vm.analysisField(item.promotionType, "promotionType"))
              ),
            ]),
            _c("td", [
              _vm._v(
                _vm._s(_vm.analysisField(item.multipleable, "multipleable"))
              ),
            ]),
            _c("td", [
              _vm._v(_vm._s(_vm.analysisField(item.firstOrder, "firstOrder"))),
            ]),
            _c("td", [
              _vm._v(
                _vm._s(_vm.analysisField(item.composeState, "composeState"))
              ),
            ]),
            _c("td", { staticStyle: { width: "300px" } }, [
              _vm._v(_vm._s(item.rangeDate)),
            ]),
          ]
        ),
        _c(
          "tr",
          {
            on: {
              click: function ($event) {
                return _vm.checkPromotion(index)
              },
            },
          },
          [
            _c(
              "td",
              { attrs: { colspan: "6" } },
              [
                _c("TableConent", {
                  attrs: { table_config: item.rule_config },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "tr",
          {
            on: {
              click: function ($event) {
                return _vm.checkPromotion(index)
              },
            },
          },
          [
            _c(
              "td",
              { attrs: { colspan: "6" } },
              [
                _c("TableConent", {
                  attrs: { table_config: item.table_config },
                }),
              ],
              1
            ),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }