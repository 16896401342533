<!--
 * @Author: yzr
 * @Date: 2020-11-26 16:28:59
 * @LastEditors: yzr
 * @LastEditTime: 2020-11-29 16:34:14
-->
<template>
  <div>
    <el-row :gutter="20"
            v-if="!disabled">
      <el-col :span="2"
              v-if="orderType !== '6'">
        <el-button type="text"
                   icon="el-icon-edit"
                   @click="openModal">添加商品</el-button>
      </el-col>
      <el-col :span="2"
              v-if="orderType !== '7'">
        <el-button type="text"
                   icon="el-icon-edit"
                   @click="openAdd">添加货补商品</el-button>
      </el-col>
      <el-col :span="2">
        <el-button type="text"
                   icon="el-icon-edit"
                   @click="clearTableData">删除</el-button>
      </el-col>
    </el-row>
    <el-scrollbar ref="myScrollbar"
                  style="max-height:600px"
                  wrap-class="content-scrollbar"
                  :native="false"
                  :noresize="false"
                  tag="div">
      <div v-for="(item,k) in tableData"
           class='table-box'
           :ref="item.key || 'productList'"
           :key="k">
        <div v-if="item.key">{{'政策编码:' + item.key}}<span class="warning-row"
                v-if="item.calSucessMsg">{{item.calSucessMsg}}</span></div>
        <el-table :data="item.data"
                  row-key="onlyKey"
                  :show-header="k===0"
                  border
                  :ref="(item.key || 'productList') + 'Table'"
                  size="mini"
                  @select-all="selectAll"
                  class="table"
                  :row-class-name="(scope) =>tableRowClassName(scope,item)"
                  default-expand-all
                  :tree-props="{children: 'giftList'}">
          <el-table-column type="selection"
                           width="55">
          </el-table-column>
          <el-table-column v-bind="columnItem.props"
                           :label="columnItem.title"
                           v-for="(columnItem,index) in tableList"
                           :prop="columnItem.field"
                           :key="index">
            <template slot-scope="scope">
              <el-popover v-if="!columnItem.props.type"
                          placement="top-start"
                          trigger="hover"
                          :disabled="!scope.row.calSucessMsg"
                          :content="scope.row.calSucessMsg">
                <span slot="reference">{{analysisField(scope,columnItem)}}</span>
              </el-popover>
              <div v-else-if="columnItem.props.type === 'input'">
                <el-input v-model="scope.row[columnItem.field]"
                          clearable
                          v-if="!scope.row.hideControl && !disabled"
                          placeholder="请输入内容"></el-input>
                <span v-else>{{analysisField(scope,columnItem)}}</span>
              </div>
              <div v-else-if="columnItem.props.type === 'inputNumber'">
                <el-input-number @change="changeNum"
                                 :disabled="disabled"
                                 v-model="scope.row[columnItem.field]"></el-input-number>
              </div>
              <div v-else-if="columnItem.field === 'promotionList' && scope.row.lineType === '0'"
                   @click="checkPromotion(scope,k)">
                <div class="tag-box"
                     v-if="scope.row.promotionList && scope.row.promotionList.length>0">
                  <div v-for="(pro,i) in scope.row.promotionList"
                       :key="i">
                    <!-- <el-popover placement="right-start"
                              width="600"
                              @show="showPromotion(scope,k,i)"
                              @hide="hidePromotion(scope,k,i)"
                              trigger="hover">
                    <PromotionDetail :promotionList="[pro]"
                                     v-if="pro.isShow"
                                     :promotionDict="promotionDict" /> -->
                    <el-popover placement="top-start"
                                trigger="hover"
                                :disabled="!pro.calSucessMsg"
                                :content="pro.calSucessMsg">
                      <el-tag size="small"
                              :closable="!disabled"
                              slot="reference"
                              class="pro-tag"
                              :class="!!pro.calSucessMsg ? 'warning-tag' : ''"
                              @close='closeTag(scope,k,i)'>{{pro.promotionName}}</el-tag>
                    </el-popover>
                  </div>

                </div>
                <div class="tag-box" v-else-if="scope.row.promotionList && scope.row.promotionList.length === 0 && disabled">
                  <el-tag class="pro-tag">
                    查看政策
                  </el-tag>
                </div>
                <div class="tag-box" v-else>
                  <el-tag class="pro-tag">
                    请选择政策
                  </el-tag>
                </div>

              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           fixed="right"
                           v-if="!disabled">
            <template slot-scope="scope">
              <i class="el-icon-delete-solid"
                 v-if="clickVisible(scope)"
                 @click="deleteProduct(scope,k)"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-scrollbar>
    <SelectConfig ref="selectConfig"
                  @onGetSelect="onGetSelect" />
    <replenishment :customerCode="customerCode"
                   :backList="value.tableData"
                   v-if="isReplenishment"
                   @closeAdd="closeAdd"
                   @submitAdd="submitAdd" />
    <Modal :modalConfig="modalConfig"
           @onClose="closeModal"
           @onOk="onModalOk">
      <ReplenOrderInfo ref="promotionList"
                       :promotionParams="promotionParams" />
    </Modal>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import Modal from '@/found/components/modal';
import { Loading } from 'element-ui';
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';
import replenishment from './replenishment.vue';

import ReplenOrderInfo from './replen_order_info';
// import PromotionDetail from './promotionDetail.vue';

import cals from './cals';

export default {
  name: 'CheckProductGoods',
  components: {
    SelectConfig,
    replenishment,
    ReplenOrderInfo,
    Modal,
    // PromotionDetail,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        tableData: [],
        backList: [],
        giftList: [],
        normalList: [],
      }),
    },
    disabled: Boolean,
    customerCode: String,
    orderType: String,
    discountAmount: Number,
  },
  watch: {
    discountAmount(val) {
      clearTimeout(this.changeTimer);
      this.changeTimer = setTimeout(() => {
        this.changeCount += 1;
        this.getOrderCal(this.changeCount);
      }, 300);
    },
    value(val) {
      // this.loadingInstance = Loading.service();
      const tableData = cals.handleCalData(val.tableData);
      this.tableData = JSON.parse(JSON.stringify(tableData));
    },
  },
  data() {
    return {
      path: {
        order: {
          cal: '/order/m/order/cal',
        },
        goods: {
          cal: '/order/m/order/cal/rebate',
        },
        free: {
          cal: '/order/m/order/cal/free',
        },
      },
      changeCount: 0,
      changeTimer: null,
      modalConfig: {
        title: '选择政策',
        visible: false,
        type: 'Full',
        width: '50%',
        showFooter: true,
      },
      promotionParams: null,
      tableData: [{ data: [] }],
      tableList: [],
      loading: true,
      isReplenishment: false,
      timer: null,
      handleIndex: {
        tableIndex: 0,
        productIndex: 0,
      },
      promotionDict: {
        multipleable: [],
        firstOrder: [],
        promotionType: [],
        composeState: [],
        company: [],
        unit: [],
        unitType: [],
      },
      dictList: [],
      modelType: '0',
    };
  },
  created() {
    cals.tableData = [
      {
        data: [],
      },
    ];
    const params = {
      function_code: 'dms_order_settle_table',
      functionCode: 'dms_order_settle_table',
    };
    request.post('/mdm/mdmtableconfig/query', params).then((res) => {
      const tableList = [];
      res.result.column.forEach((v) => {
        const rowData = v;
        rowData.props = this.mateFieldType(rowData.field, rowData.title);
        if (!rowData.props.hidden) {
          tableList.push(rowData);
        }
      });
      this.tableList = tableList;
      // console.log(this.value.tableData);
    });
    // this.getDictSelect();
    this.getBatchDictSelect();
  },
  methods: {
    deleteProduct(scope, k) {
      let tableData = cals.deleteProduct(k, scope.$index);
      if (tableData.length > 0) {
        this.loadingInstance = Loading.service();
        this.changeCount = 0;
        this.getOrderCal(this.changeCount);
      } else {
        tableData = [{ data: [] }];
        this.tableData = tableData;
        cals.tableData = tableData;
        this.$emit('quantityOrder', {
          actualAmount: 0,
          discountAmount: 0,
          maxDiscountAmount: 0,
          repAmount: 0,
          promotionAmount: 0,
          amount: 0,
        });
      }
    },
    changeNum() {
      clearTimeout(this.changeTimer);
      this.changeTimer = setTimeout(() => {
        cals.tableData = cals.copyData(this.tableData);
        this.changeCount += 1;
        this.getOrderCal(this.changeCount);
      }, 300);
    },
    tableRowClassName({ row }, item) {
      if (row.calSucessMsg || item.calSucessMsg) {
        return 'warning-row';
      }
      return '';
    },
    // 获取数据字典
    getBatchDictSelect() {
      const params = [
        'yesOrNo',
        'promotion_type',
        'saleGoal_unitType',
        'saleCompany',
        'product_sale_unit',
        'unit_type',
      ];
      request.post('/mdm/mdmdictdata/batchDictSelect', params).then((res) => {
        if (res.success) {
          this.promotionDict = {
            multipleable: res.result.yesOrNo,
            firstOrder: res.result.yesOrNo,
            composeState: res.result.yesOrNo,
            promotionType: res.result.promotion_type,
            company: res.result.saleCompany,
            unit: res.result.product_sale_unit,
            unitType: res.result.unit_type,
          };
          cals.promotionDict = this.promotionDict;
        }
      });
    },
    showPromotion(scope, k, i) {
      const tableData = JSON.parse(JSON.stringify(this.tableData));
      tableData[k].data[scope.$index].promotionList[i].isShow = true;
      this.tableData = tableData;
    },
    hidePromotion(scope, k, i) {
      const tableData = JSON.parse(JSON.stringify(this.tableData));
      tableData[k].data[scope.$index].promotionList[i].isShow = false;
      this.tableData = tableData;
    },
    closeModal() {
      this.modalConfig.visible = false;
    },
    onModalOk() {
      this.loadingInstance = Loading.service();
      cals.handleBuyGifts(
        this.$refs.promotionList.promotionList,
        this.handleIndex,
      );
      this.modalConfig.visible = false;
      this.changeCount = 0;
      this.getOrderCal(this.changeCount);

      // this.scrollToPromotion();
    },
    // 隐藏按钮
    clickVisible(scope) {
      return scope.row.lineType !== '1';
    },
    // 解析字段
    analysisField(scope, item) {
      if (item.field === 'lineType') {
        if (scope.row.lineType === '1') {
          return '赠品';
        }
        if (scope.row.lineType === '2') {
          return '货补';
        }
        return '本品';
      }
      if (item.field === 'saleUnit') {
        return scope.row.saleUnitName;
      }

      return scope.row[item.field];
    },
    // 匹配类型
    mateFieldType(field, title) {
      const con = document.createElement('canvas').getContext('2d');
      con.font = '700 12px Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif'; // 自己需要的字体什么的
      // 生成宽度查询函数
      const strlen = (str) => con.measureText(str).width;
      let minWidth = strlen(title) + 20 > 100 ? strlen(title) + 20 : 100;
      minWidth = Math.ceil(minWidth) + 10;
      let rowProps = {
        align: 'center',
        showOverflowTooltip: true,
        minWidth,
        // width: 50,
      };
      switch (field) {
        case 'productNum':
          rowProps = {
            ...rowProps,
            type: 'inputNumber',
            width: '200',
          };
          break;
        case 'promotionList':
          rowProps = {
            ...rowProps,
            type: 'select',
            width: '200',
            hidden: this.orderType === '6' || this.orderType === '7',
          };
          break;
        case 'promotionPolicyCode':
          rowProps = {
            ...rowProps,
            width: '200',
            hidden: this.orderType === '6' || this.orderType === '7',
          };
          break;
        default:
          break;
      }
      return rowProps;
    },
    // 清空
    clearTableData() {
      this.loadingInstance = Loading.service();
      const tableData = [];
      this.tableData.forEach((v) => {
        const data = [];
        this.$refs[`${v.key || 'productList'}Table`][0].selection.forEach((a) => {
          data.push(a);
        });
        tableData.push({
          ...v,
          data,
        });
      });
      cals.tableData = tableData;
      this.changeCount = 0;
      this.getOrderCal(this.changeCount);
    },
    openAdd() {
      if (!this.customerCode) {
        this.$message('请选择客户');
        return false;
      }
      this.modelType = '2';
      const data = [];
      cals.tableData[cals.tableData.length - 1].data.forEach((v) => {
        if (v.lineType === '2') {
          data.push({
            ...v,
            onlyCode: v.feeCode + v.productCode,
          });
        }
      });
      let params = null;
      params = {
        functionCode: 'dms_goods_product',
        data,
        idKey: 'onlyCode',
        paramData: {
          customerCode: this.customerCode,
        },
        hasChild: 'poolCode',
        expandAll: true,
        afterGetList: (tableData) => {
          const list = cals.analysisGoods(tableData);
          return list;
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 打开弹窗
    openModal() {
      if (!this.customerCode) {
        this.$message('请选择客户');
        return false;
      }
      this.modelType = '0';
      const normalList = [];
      this.value.tableData.forEach((v) => {
        if (v.lineType === '0') {
          normalList.push(v);
        }
      });
      let params = null;
      if (this.orderType === '7') {
        params = {
          functionCode: 'dms_free_product',
          data: normalList,
          idKey: 'productCode',
          paramData: {
            customerCode: this.customerCode,
            conditionTypeCode: 'sale_price',
          },
        };
      } else {
        params = {
          functionCode: 'dms_check_product',
          data: normalList,
          idKey: 'productCode',
          paramData: {
            cusCode: this.customerCode,
          },
        };
      }

      this.$refs.selectConfig.openSelectModal(params);
    },
    onGetSelect(val) {
      this.loadingInstance = Loading.service();
      cals.addProduct(val, this.modelType);
      this.changeCount = 0;
      this.getOrderCal(this.changeCount);
    },
    // 请求算价
    getOrderCal(changeCount = 0) {
      const groupItemVos = cals.handleSubmitCal();
      if (groupItemVos.length === 0) {
        return false;
      }
      const params = {
        cusCode: this.customerCode,
        discountAmount: this.discountAmount,
        fromType: 4,
        groupItemVos,
      };
      let path = '';
      switch (this.orderType) {
        case '1':
          path = this.path.order.cal;
          break;
        case '6':
          path = this.path.goods.cal;
          break;
        case '7':
          path = this.path.free.cal;
          break;
        default:
          break;
      }
      request
        .post(path, params)
        .then((res) => {
          if (res.success) {
            if (changeCount < this.changeCount) {
              return false;
            }
            this.$emit('change', {
              calSucessMsg: '',
              calSucessFlag: false,
            });
            const tableData = cals.handleCalData(res.result.groupItemVos);
            this.tableData = JSON.parse(JSON.stringify(tableData));
            const {
              actualAmount,
              discountAmount,
              maxDiscountAmount,
              repAmount,
              promotionAmount,
              amount,
            } = res.result;
            this.$emit('quantityOrder', {
              actualAmount: actualAmount < 0 ? 0 : actualAmount,
              discountAmount: discountAmount < 0 ? 0 : discountAmount,
              maxDiscountAmount: maxDiscountAmount < 0 ? 0 : maxDiscountAmount,
              repAmount: repAmount < 0 ? 0 : repAmount,
              promotionAmount: promotionAmount < 0 ? 0 : promotionAmount,
              amount: amount < 0 ? 0 : amount,
            });
          }
          if (res.result.calSucessMsg) {
            this.$message.error(res.result.calSucessMsg);
            this.$emit('change', {
              calSucessMsg: res.result.calSucessMsg,
              calSucessFlag: true,
            });
          }
          this.scrollToPromotion();
          if (this.loadingInstance) {
            this.loadingInstance.close();
          }
        })
        .catch((err) => {
          this.loadingInstance = Loading.service();
          this.loadingInstance.close();
        });
    },
    scrollToPromotion() {
      this.$nextTick(() => {
        cals._self = this;
        const scrollTop = cals.getScrollTop();
        // console.log(scrollTop);

        if (typeof scrollTop === 'number') {
          // this.$refs.myScrollbar.wrap.scrollTop = scrollTop;
        }
      });
    },
    closeTag(scope, k, i) {
      this.loadingInstance = Loading.service();
      cals.deletePromotion(scope.row, k, scope.$index, i);
      this.changeCount = 0;
      this.getOrderCal(this.changeCount);
      // this.scrollToPromotion();
    },
    checkPromotion(scope, index) {
      this.handleIndex = {
        tableIndex: index,
        productIndex: scope.$index,
      };
      this.promotionParams = {
        accountCode: this.customerCode,
        accountType: 'customer',
        saleProductVos: [{ productCode: scope.row.productCode }],
      };
      cals.checkPromotion = JSON.parse(JSON.stringify(scope.row.promotionList));
      if (this.disabled) {
        this.modalConfig.showFooter = false;
      }
      this.modalConfig.visible = true;
    },
    selectAll(selection) {
      if (selection.length === 0) {
        this.tableData.forEach((v) => {
          this.$refs[`${v.key || 'productList'}Table`][0].clearSelection();
        });
      } else {
        this.tableData.forEach((v) => {
          v.data.forEach((a) => {
            this.$refs[`${v.key || 'productList'}Table`][0].toggleRowSelection(
              a,
              true,
            );
          });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  width: 100%;
  margin-bottom: 12px;
}
/deep/.giftStyle {
  background: #f5f7fa94;
}

/deep/.warning-row {
  // background: rgba(255, 0, 0, 0.3) !important;
  color: red;
}

/deep/.isErr {
  // background:red;
  color: red;
}

/deep/.el-table th,
.el-table tr {
  background: #e8e8e8 !important;
}
/deep/.content-scrollbar {
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  height: 100%;
}
.tag-box {
  display: flex;
  flex-direction: column;
  .pro-tag {
    margin-bottom: 6px;
    cursor: pointer;
  }
  .warning-tag {
    background-color: #fef0f0 !important;
    border-color: #fde2e2 !important;
    color: #f56c6c !important;
    /deep/.el-tag__close {
      color: #f56c6c !important;
      &:hover {
        background-color: #f56c6c !important;
        border-color: #f56c6c !important;
        color: #ffffff !important;
      }
    }
  }
}
</style>
