var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.orderType !== "6"
                ? _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-edit" },
                          on: { click: _vm.openModal },
                        },
                        [_vm._v("添加商品")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.orderType !== "7"
                ? _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-edit" },
                          on: { click: _vm.openAdd },
                        },
                        [_vm._v("添加货补商品")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "el-icon-edit" },
                      on: { click: _vm.clearTableData },
                    },
                    [_vm._v("删除")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-scrollbar",
        {
          ref: "myScrollbar",
          staticStyle: { "max-height": "600px" },
          attrs: {
            "wrap-class": "content-scrollbar",
            native: false,
            noresize: false,
            tag: "div",
          },
        },
        _vm._l(_vm.tableData, function (item, k) {
          return _c(
            "div",
            {
              key: k,
              ref: item.key || "productList",
              refInFor: true,
              staticClass: "table-box",
            },
            [
              item.key
                ? _c("div", [
                    _vm._v(_vm._s("政策编码:" + item.key)),
                    item.calSucessMsg
                      ? _c("span", { staticClass: "warning-row" }, [
                          _vm._v(_vm._s(item.calSucessMsg)),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c(
                "el-table",
                {
                  ref: (item.key || "productList") + "Table",
                  refInFor: true,
                  staticClass: "table",
                  attrs: {
                    data: item.data,
                    "row-key": "onlyKey",
                    "show-header": k === 0,
                    border: "",
                    size: "mini",
                    "row-class-name": function (scope) {
                      return _vm.tableRowClassName(scope, item)
                    },
                    "default-expand-all": "",
                    "tree-props": { children: "giftList" },
                  },
                  on: { "select-all": _vm.selectAll },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._l(_vm.tableList, function (columnItem, index) {
                    return _c(
                      "el-table-column",
                      _vm._b(
                        {
                          key: index,
                          attrs: {
                            label: columnItem.title,
                            prop: columnItem.field,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    !columnItem.props.type
                                      ? _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top-start",
                                              trigger: "hover",
                                              disabled: !scope.row.calSucessMsg,
                                              content: scope.row.calSucessMsg,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "reference" },
                                                slot: "reference",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.analysisField(
                                                      scope,
                                                      columnItem
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : columnItem.props.type === "input"
                                      ? _c(
                                          "div",
                                          [
                                            !scope.row.hideControl &&
                                            !_vm.disabled
                                              ? _c("el-input", {
                                                  attrs: {
                                                    clearable: "",
                                                    placeholder: "请输入内容",
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row[
                                                        columnItem.field
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        columnItem.field,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[columnItem.field]",
                                                  },
                                                })
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.analysisField(
                                                        scope,
                                                        columnItem
                                                      )
                                                    )
                                                  ),
                                                ]),
                                          ],
                                          1
                                        )
                                      : columnItem.props.type === "inputNumber"
                                      ? _c(
                                          "div",
                                          [
                                            _c("el-input-number", {
                                              attrs: { disabled: _vm.disabled },
                                              on: { change: _vm.changeNum },
                                              model: {
                                                value:
                                                  scope.row[columnItem.field],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    columnItem.field,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row[columnItem.field]",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : columnItem.field === "promotionList" &&
                                        scope.row.lineType === "0"
                                      ? _c(
                                          "div",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.checkPromotion(
                                                  scope,
                                                  k
                                                )
                                              },
                                            },
                                          },
                                          [
                                            scope.row.promotionList &&
                                            scope.row.promotionList.length > 0
                                              ? _c(
                                                  "div",
                                                  { staticClass: "tag-box" },
                                                  _vm._l(
                                                    scope.row.promotionList,
                                                    function (pro, i) {
                                                      return _c(
                                                        "div",
                                                        { key: i },
                                                        [
                                                          _c(
                                                            "el-popover",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "top-start",
                                                                trigger:
                                                                  "hover",
                                                                disabled:
                                                                  !pro.calSucessMsg,
                                                                content:
                                                                  pro.calSucessMsg,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-tag",
                                                                {
                                                                  staticClass:
                                                                    "pro-tag",
                                                                  class:
                                                                    !!pro.calSucessMsg
                                                                      ? "warning-tag"
                                                                      : "",
                                                                  attrs: {
                                                                    slot: "reference",
                                                                    size: "small",
                                                                    closable:
                                                                      !_vm.disabled,
                                                                  },
                                                                  on: {
                                                                    close:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.closeTag(
                                                                          scope,
                                                                          k,
                                                                          i
                                                                        )
                                                                      },
                                                                  },
                                                                  slot: "reference",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      pro.promotionName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : scope.row.promotionList &&
                                                scope.row.promotionList
                                                  .length === 0 &&
                                                _vm.disabled
                                              ? _c(
                                                  "div",
                                                  { staticClass: "tag-box" },
                                                  [
                                                    _c(
                                                      "el-tag",
                                                      {
                                                        staticClass: "pro-tag",
                                                      },
                                                      [_vm._v(" 查看政策 ")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  { staticClass: "tag-box" },
                                                  [
                                                    _c(
                                                      "el-tag",
                                                      {
                                                        staticClass: "pro-tag",
                                                      },
                                                      [_vm._v(" 请选择政策 ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        "el-table-column",
                        columnItem.props,
                        false
                      )
                    )
                  }),
                  !_vm.disabled
                    ? _c("el-table-column", {
                        attrs: { label: "操作", fixed: "right" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.clickVisible(scope)
                                    ? _c("i", {
                                        staticClass: "el-icon-delete-solid",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteProduct(scope, k)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        }),
        0
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
      _vm.isReplenishment
        ? _c("replenishment", {
            attrs: {
              customerCode: _vm.customerCode,
              backList: _vm.value.tableData,
            },
            on: { closeAdd: _vm.closeAdd, submitAdd: _vm.submitAdd },
          })
        : _vm._e(),
      _c(
        "Modal",
        {
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.closeModal, onOk: _vm.onModalOk },
        },
        [
          _c("ReplenOrderInfo", {
            ref: "promotionList",
            attrs: { promotionParams: _vm.promotionParams },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }