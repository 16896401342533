<!--
 * @Author: yzr
 * @Date: 2020-11-24 14:24:20
 * @LastEditors: yzr
 * @LastEditTime: 2020-12-08 15:51:39
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';

import CheckProduct from './components/check_product.vue';
import CheckCustomer from './components/check_customer.vue';
import SettleGoods from './components/settle_goods.vue';
import cals from './components/cals';

formCreate.component('CheckProduct', CheckProduct);
formCreate.component('CheckCustomer', CheckCustomer);
formCreate.component('SettleGoods', SettleGoods);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      // form内组件
      calSucessMsg: '',
      calSucessFlag: false,
      rule: [],
      buttons: {
        submitAudit: true,
      },
      buttonText: {
        submitAudit: '暂存',
      },
      path: {
        order: {
          add: '/order/m/order/add',
        },
        goods: {
          add: '/order/m/order/add/rebate',
        },
        free: {
          add: '/order/m/order/add/free',
        },
      },
    };
  },

  async mounted() {
    if (this.formConfig.row && this.formConfig.row.orderType !== '1') {
      this.buttons.submitAudit = false;
    }
    if (this.formConfig.code === 'view') {
      this.buttons.submitAudit = false;
    }
    this.rule = await this.getFormRule('dms_order_manage_form');
  },

  methods: {
    formComplete() {
      this.hiddenFields(true, ['type']);
      if (this.formConfig.row.orderType) {
        this.setValue({
          type: this.formConfig.row.orderType,
        });
        this.getRule('items').props.orderType = this.formConfig.row.orderType;
      }
      if (this.formConfig.code !== 'add') {
        this.getData();
      }
      setTimeout(() => {
        console.log(this.fApi.el('items').$el.offsetTop);
      }, 1000);
    },
    setRule(v) {
      const rowData = v;
      rowData.props = {
        ...rowData.props,
        disabled: this.disabledField(rowData),
      };
      if (rowData.field === 'cusName') {
        rowData.on = {
          ...rowData.on,
          setcustomerName: this.setcustomerName,
        };
      }
      if (rowData.field === 'items') {
        rowData.type = 'SettleGoods';
        rowData.props = {
          ...rowData.props,
          discountAmount: 0,
          // customerCode: '',
          customerCode: 'FLPOC2020001',
          errMsg: '',
          errType: false,
        };
        rowData.on = {
          ...rowData.on,
          change: this.changeCal,
          quantityOrder: this.quantityOrder,
        };
        rowData.value = { tableData: [] };
      }
      if (rowData.field === 'temporaryFlag') {
        rowData.value = 'N';
      }
      if (rowData.field === 'pickAdress') {
        rowData.restful = '/mdm/mdmCustomerAddressController/listCondition';
        rowData.headers = { functionCode: 'select_pick_address' };
        rowData.refresh = true;
        rowData.restfulParams = {
          customerCode: '',
        };
        rowData.optionsKey = {
          value: 'detailedAddress',
          label: 'detailedAddress',
        };
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            const data = rowData.options.find((a) => a.detailedAddress === val);
            this.setValue({
              contactsName: data.contactName,
              contactsPhone: data.contactPhone,
            });
          },
        };
        rowData.props = {
          ...rowData.props,
          disabled: true,
        };
      }
      if (rowData.field === 'discountAmount') {
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            this.getRule('items').props.discountAmount = val;
          },
        };
        if (this.formConfig.row.orderType === '6' || this.formConfig.row.orderType === '7') {
          rowData.props = {
            ...rowData.props,
            disabled: true,
          };
        }
      }
      return rowData;
    },
    setcustomerName(val) {
      this.setValue({
        cusName: val.customerName,
        cusCode: val.customerCode,
      });
      this.searchCustomerAmount();
      this.getRule('items').props.customerCode = val.customerCode;
      this.getRule('pickAdress').props.disabled = false;
      this.getRule('pickAdress').restfulParams = {
        customerCode: val.customerCode,
      };
      this.setValue({
        items: { tableData: [] },
      });
    },
    // 查询客户余额
    searchCustomerAmount() {
      const params = {
        customerCode: this.getFieldValue('cusCode'),
      };
      request
        .post('/fee/feePoolController/queryPoolAmount', params)
        .then((res) => {
          if (res.success) {
            this.setValue({
              usableAmount: res.result.discountUsableAmount < 0 ? 0 : res.result.discountUsableAmount,
            });
          }
        });
    },
    disabledField(row) {
      let isDisabled = false;
      switch (row.field) {
        case 'contactsName':
          isDisabled = true;
          break;
        case 'contactsPhone':
          isDisabled = true;
          break;
        case 'amount':
          isDisabled = true;
          break;
        case 'promotionAmount':
          isDisabled = true;
          break;
        case 'repAmount':
          isDisabled = true;
          break;
        case 'usableAmount':
          isDisabled = true;
          break;
        case 'maxDiscountAmount':
          isDisabled = true;
          break;
        case 'discountAmount':
          isDisabled = false;
          break;
        case 'actualAmount':
          isDisabled = true;
          break;
        case 'cusCode':
          isDisabled = true;
          break;
        default:
          break;
      }
      return isDisabled;
    },
    // 解析返回的列表数据
    handleTableData(groupItemVos) {
      const tableData = [];
      groupItemVos.forEach((v) => {
        if (v.giftList) {
          v.giftList.map((a) => {
            const rowData = a;
            rowData.hideControl = true;
            rowData.lineType = '20';
            if (v.normalList) {
              rowData.parentProductCode = v.normalList[0].productCode;
            }

            // rowData.promotionPolicyName = rowData.feeName;
            // rowData.promotionPolicyCode = rowData.feeCode;
            return rowData;
          });
        }
        if (v.normalList) {
          v.normalList.forEach((a) => {
            const {
              promotionPolicyId,
              promotionType,
              promotionResultDate,
              promotionResultType,
              lineNo,
            } = a;
            const aData = a;
            if (promotionType !== '0') {
              aData.resultDataType = promotionResultType;
              aData.resultData = promotionResultDate;
            } else {
              // console.log(resultData);
              const giftList = [];
              if (a.giftList) {
                a.giftList.map((b) => {
                  const bData = b;
                  bData.hideControl = true;
                  bData.lineType = '20';
                  bData.promotionPolicyName = aData.promotionPolicyInfo;
                  bData.promotionPolicyCode = aData.promotionPolicyCode;
                  bData.parentProductCode = aData.productCode;
                  bData.productNum = promotionResultType === 'number'
                    ? promotionResultDate
                    : '0';
                  bData.amount = promotionResultType === 'amount'
                    ? promotionResultDate
                    : '-';
                  bData.parentProductCode = aData.productCode;
                  return bData;
                });
              }

              if (promotionResultType === 'amount') {
                if (a.giftList) {
                  a.giftList.map((c) => {
                    const cData = c;
                    cData.amount = promotionResultType === 'amount'
                      ? promotionResultDate
                      : '-';
                    cData.parentProductCode = aData.productCode;
                    cData.productNum = Math.floor(cData.amount / cData.price);
                    return cData;
                  });
                }
              }
            }
            tableData.push({
              ...aData,
              giftList: v.giftList || [],
              lineType: '10',
              // promotionPolicyName: a.feeName,
              // promotionPolicyCode: a.feeCode,
            });
          });
        }
        if (v.backList) {
          v.backList.forEach((a) => {
            tableData.push({
              ...a,
              hideControl: true,
              lineType: '30',
            });
          });
        }
      });
      // console.log(tableData);
      return tableData;
    },
    getData() {
      let path = '/order/m/order/findDetailsById/';
      if (this.formConfig.code === 'view') {
        path = '/order/m/order/findSimpleDetailsById/';
      }
      request.get(path + this.formConfig.row.id).then((res) => {
        if (res.success) {
          const { groupItemVos } = res.result;
          // const tableData = this.handleTableData(groupItemVos);
          this.formConfig.row.orderType = `${res.result.type}`;
          if (this.formConfig.row.orderType !== '1') {
            this.buttons.submitAudit = false;
          } else {
            this.buttons.submitAudit = true;
          }
          if (this.formConfig.code === 'view') {
            this.buttons.submitAudit = false;
          }

          this.getRule('items').props.customerCode = res.result.cusCode;
          this.getRule('items').props.disabled = this.formConfig.code === 'view';
          this.getRule('items').props.orderType = `${res.result.type}`;
          this.getRule('pickAdress').props.disabled = this.formConfig.code === 'view';
          this.getRule('pickAdress').restfulParams = {
            customerCode: res.result.cusCode,
          };
          this.setValue({
            ...res.result,
            items: {
              tableData: groupItemVos,
            },
          });
          this.searchCustomerAmount();
        }
      });
    },
    // 计算价格
    quantityOrder(val) {
      // console.log(val);
      this.setValue(val);
    },

    changeCal(val) {
      this.calSucessMsg = val.calSucessMsg;
      this.calSucessFlag = val.calSucessFlag;
    },
    // 提交表单
    submit(btn) {
      if (this.calSucessFlag) {
        this.$message.error(this.calSucessMsg);
        return false;
      }
      if (!this.getFormData()) return false;
      const groupItemVos = cals.handleSubmitCal();
      const params = {
        ...this.getFormData(),
        fromType: 4,
        groupItemVos,
      };
      delete params.items;
      let path = '';
      switch (this.formConfig.row.orderType) {
        case '1':
          path = this.path.order.add;
          break;
        case '6':
          path = this.path.goods.add;
          break;
        case '7':
          path = this.path.free.add;
          break;
        default:
          break;
      }
      if (btn === 2) {
        path = '/order/m/order/temporary/add';
        if (this.formConfig.code === 'edit') {
          path = '/order/m/order/edit';
        }
      }
      request.post(path, params).then((res) => {
        if (res.success) {
          this.$message.success('操作成功');
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
