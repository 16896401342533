<!--
 * @Description: 促销政策弹框
 * @Autor: txp
 * @Date: 2021-04-27 17:55:11
-->
<template>
  <div class="replenorderinfo">
    <table class="reptable"
           v-for="(item,index) in promotionList"
           :key="index">
      <tr>
        <th class="checkeds">
          <el-checkbox :value="item.checked" @change="checkPromotion(index)"></el-checkbox>
        </th>
        <th @click="checkPromotion(index)">政策名称</th>
        <th @click="checkPromotion(index)">政策类型</th>
        <th @click="checkPromotion(index)">是否叠加</th>
        <th @click="checkPromotion(index)">是否首单</th>
        <th @click="checkPromotion(index)">是否组合</th>
        <th @click="checkPromotion(index)" style="width:300px">有效期</th>
      </tr>
      <tr class="cont_td" @click="checkPromotion(index)">
        <td rowspan="4"></td>
        <td>{{item.promotionName}}</td>
        <td>{{analysisField(item.promotionType,'promotionType')}}</td>
        <td>{{analysisField(item.multipleable,'multipleable')}}</td>
        <td>{{analysisField(item.firstOrder,'firstOrder')}}</td>
        <td>{{analysisField(item.composeState,'composeState')}}</td>
        <td style="width:300px">{{item.rangeDate}}</td>
      </tr>
      <tr @click="checkPromotion(index)">
        <td colspan="6">
          <TableConent :table_config="item.rule_config" />
        </td>
      </tr>
      <tr @click="checkPromotion(index)">
        <td colspan="6">
          <TableConent :table_config="item.table_config" />
        </td>
      </tr>
      <!-- <tr v-if="item.promotionType === '0'" @click="checkPromotion(index)">
        <td colspan="6">
          <TableConent :table_config="item.gift_config" />
        </td>
      </tr> -->
    </table>
  </div>
</template>
<script>
import request from '@/found/utils/request';
import TableConent from './table_conent';

// import res from './a.js';
import cals from './cals';

export default {
  name: 'replenorderinfo',
  data() {
    return {
      checked: false,
      promotionList: [],
    };
  },
  props: {
    promotionParams: Object,
  },
  components: {
    TableConent,
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      request.post('/dms/promotion/v2/findAvailablePromotionsByAccount', this.promotionParams).then((res) => {
        if (res.success) {
          this.promotionList = cals.analysisPromotion(res.result[0].promotions);
        }
      });
    },
    analysisField(data, field) {
      return cals.analysisField(data, field);
    },
    checkPromotion(index) {
      cals._self = this;
      const result = cals.changePromotion(this.promotionList, index);
      if (result.type === 'sameType') {
        this.$confirm('已选中同类活动，是否切换?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.promotionList = cals.changeSameType(result);
        }).catch(() => {

        });
      } else if (result.type === 'notMultipleable') {
        this.$confirm('当前政策或已选政策不可叠加，是否切换?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.promotionList = cals.changeMultipleable(result);
        }).catch(() => {

        });
      } else {
        this.promotionList = result.list;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.replenorderinfo {
  width: 100%;
  .reptable {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #d8d8d8;
    margin-bottom: 18px;
    .cont_td {
      td {
        padding: 12px 0px;
        width: 20%;
      }
    }
    .checkeds {
      padding: 15px 12px;
    }
    th {
      border: 1px solid #d8d8d8;
      text-align: center;
      font-size: 13px;
      background: #f5f5f5;
      padding: 12px 0px;
      color: #909399;
      width: 80px;
    }
    td {
      border: 1px solid #d8d8d8;
      text-align: center;
      font-size: 13px;
    }
  }
}
</style>
