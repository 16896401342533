<template>
  <div>
    <Modal :modalConfig="modalConfig" @onClose="dialog_btnClose">
      <div class="huobu_title">货补池</div>
      <!-- 查询表单 -->
      <el-form inline :model="form" ref="form">
        <el-row :gutter="24">
          <el-col :md="5">
            <el-form-item prop="goodsProductName">
              <el-input type="text" v-model="form.goodsProductName" placeholder="商品名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="5">
            <el-form-item prop="goodsProductCode">
              <el-input type="text" v-model="form.goodsProductCode" placeholder="商品编码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="4" style="text-align: right;">
            <el-button type="primary" @click="search">查询</el-button>
            <el-button @click="reset('form')">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" class="md20" @click="add">批量添加</el-button>
      <!-- 货补池表格 -->
      <vxe-table ref="xTable"
                row-id="id"
                :tree-config="{children:'productList',expandAll:true}"
                  :edit-config="{trigger: 'click', mode: 'cell'}"
                  :data="tableData">
          <!-- <vxe-table-column type="seq" width="60" title="项目" ></vxe-table-column> -->
          <vxe-table-column field="goodsProductCode" title="货补商品编码" tree-node></vxe-table-column>
          <vxe-table-column field="usableAmount" title="可用余额（元）" ></vxe-table-column>
          <vxe-table-column field="goodsProductName" title="货补商品"></vxe-table-column>
          <vxe-table-column field="spec" title="规格"></vxe-table-column>
          <vxe-table-column field="saleUnitName" title="单位"></vxe-table-column>
          <vxe-table-column field="productNum" title="选择数量" :edit-render="{name: '$input', events: {change: ChangeNumEvent}}"></vxe-table-column>
          <vxe-table-column field="useAmount" title="本单已选金额"></vxe-table-column>
        </vxe-table>
      <!-- 已选货补商品 -->
      <div class="huobu_title yibuhuo_title">已选货补商品</div>
      <el-button type="primary" class="md20" @click="tableDel">批量删除</el-button>
      <!-- 已选货补商品表格 -->
      <vxe-table ref="xCheckTable" class="md20"
                row-id="id"
        @checkbox-all="({records}) => selectAllEvent({records})"
                  @checkbox-change="({records}) => selectChangeEvent({records})"
                  :edit-config="{trigger: 'click', mode: 'cell'}"
                  :data="checkTableData">
          <vxe-table-column type="checkbox"
                            width="50"></vxe-table-column>
          <vxe-table-column field="goodsProductCode" title="商品编码"></vxe-table-column>
          <vxe-table-column field="goodsProductName" title="商品名称"></vxe-table-column>
          <vxe-table-column field="spec" title="规格"></vxe-table-column>
          <vxe-table-column field="saleUnitName" title="单位"></vxe-table-column>
          <vxe-table-column field="productNum" title="数量"></vxe-table-column>
          <vxe-table-column field="useAmount" title="金额"></vxe-table-column>
        </vxe-table>
      <div class="md20">本单货补可用金额（元）：{{usableAmount}}</div>
      <div class="pd20">本单货补已用金额（元）：{{useAmount}}</div>
      <div class="dialog-footer">
        <el-button type="primary" @click="dialog_btnConfirm" size="small" icon="el-icon-check">提交 </el-button>
        <el-button type="danger" @click="dialog_btnClose" size="small" icon="el-icon-close">关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
import request from '@/found/utils/request';
import Modal from '../../../../../../../components/modal';

export default {
  components: {
    Modal,
  },
  props: {
    customerCode: String,
    backList: Array,
  },
  data() {
    return {
      tableData: [],
      checkTableData: [],
      selectionList: [],
      modalConfig: {
        title: '批量添加产品折扣',
        visible: true,
        type: 'Modal',
        width: '80%',
      },
      form: {
        goodsProductCode: '',
        goodsProductName: '',
      },
      useAmount: 0,
      usableAmount: 0,
    };
  },
  created() {
    const backList = [];
    this.backList.forEach((v) => {
      if (v.lineType === '30') {
        backList.push(v);
      }
    });
    this.checkTableData = backList;
    this.checkTableData.map((v) => {
      const item = v;
      this.useAmount += item.useAmount;
      return item;
    });
    this.getTableList();
    this.getAmount();
  },
  methods: {
    // 获取商品列表数据
    getTableList() {
      const data = {
        customerCode: this.customerCode,
        ...this.form,
      };
      request.post('/fee/feePoolGoodsController/findFeePoolGoodsProductGroupList', data).then((res) => {
        if (res.success) {
          this.tableData = res.result.data;
          this.tableData.map(async (v) => {
            const rowData = v;
            const productCodes = [];
            rowData.productList.map((a) => {
              const aData = a;
              aData.goodsProductName = a.productName;
              aData.goodsProductCode = a.productCode;
              aData.feeCode = v.poolCode;
              aData.imgUrl = a.mainPictureUrl;
              productCodes.push(a.productCode);
              return aData;
            });
            rowData.productList = await this.getPrice(productCodes, rowData.productList);
            return rowData;
          });
          console.log(this.tableData);
        }
      });
    },
    // 批量添加
    add() {
      const arr = [];
      this.useAmount = 0;
      this.tableData.map((v) => {
        const item = v;
        if (item.productList) {
          item.productList.forEach((a) => {
            if (a.productNum) {
              arr.push(a);
            }
          });
        }
        return item;
      });
      if (arr.length) {
        this.checkTableData = JSON.parse(JSON.stringify(arr));
        this.checkTableData.map((v) => {
          const item = v;
          this.useAmount += item.useAmount;
          return item;
        });
      } else {
        this.$message.error('请选择数量');
      }
    },
    // 批量删除
    tableDel() {
      this.selectionList.map((v) => {
        const item = v;
        const index = this.checkTableData.findIndex((val) => val.id === item.id);
        if (index > -1) {
          this.checkTableData.splice(index, 1);
        }
        // 被删除的数据在货补池表格中清0
        const zeroIndex = this.tableData.findIndex((val) => val.id === item.id);
        if (zeroIndex > -1) {
          this.tableData[zeroIndex].productNum = null;
        }
        return item;
      });
    },
    // 提交弹窗
    dialog_btnConfirm() {
      if (this.checkTableData.length) {
        this.$emit('submitAdd', this.checkTableData);
      } else {
        this.$message.error('请选择数据');
      }
    },
    // 关闭弹窗
    dialog_btnClose() {
      this.$emit('closeAdd');
    },
    ChangeNumEvent(val) {
      const { row } = val;
      if (!val.row.productNum && val.row.productNum !== '0') {
        const index = this.checkTableData.findIndex((v) => v.id === val.row.id);
        if (index > -1) {
          this.checkTableData.splice(index, 1);
        }
      } else {
        row.useAmount = this.floatMul(row.productNum, row.price);
      }
    },
    // 乘
    floatMul(arg1 = 0, arg2 = 0) {
      let m = 0;
      const s1 = arg1.toString();
      const s2 = arg2.toString();
      // eslint-disable-next-line no-empty
      try { m += s1.split('.')[1].length; } catch (e) {}
      // eslint-disable-next-line no-empty
      try { m += s2.split('.')[1].length; } catch (e) {}
      // eslint-disable-next-line no-mixed-operators
      return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / 10 ** m;
    },
    selectAllEvent({ records }) {
      this.selectionList = records;
    },
    selectChangeEvent({ records }) {
      this.selectionList = records;
    },
    // 搜索
    search() {
      this.getTableList();
    },
    // 重置
    reset(formName) {
      this.$refs[formName].resetFields();
      this.getTableList();
    },
    // 获取价格
    async getPrice(productCodes, normalList) {
      const params = {
        conditionTypeCodeList: ['sale_price'],
        customerCode: this.customerCode,
        productCodeList: productCodes,
      };
      const list = normalList;
      await request.post('/mdm/mdmPriceSettingController/search', params).then((res) => {
        if (res.success) {
          if (res.result) {
            for (const i in res.result) {
              if (res.result[i]) {
                let salePrice = 0;
                if (res.result[i].sale_price) {
                  salePrice = res.result[i].sale_price ? res.result[i].sale_price.price : 0;
                }
                list.map((v) => {
                  const rowData = v;
                  if (rowData.productCode === i) {
                    rowData.price = salePrice || 0;
                    rowData.amount = salePrice || 0;
                  }
                  return rowData;
                });
              }
            }
          }
        }
      });
      // console.log(list);
      return list;
    },
    // 获取金额
    getAmount() {
      request.get('/fee/feePoolGoodsController/queryGoodsAmountByCustomerCode', { customerCode: this.customerCode }).then((res) => {
        if (res.success) {
          this.usableAmount = res.result.usableAmount || 0;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
  /deep/ .el-form-item, /deep/ .el-form-item__content{
    width: 100%;
  }
  .huobu_title{
    font-size: 16px;
    color: #666;
    padding-bottom: 20px;
  }
  .md20{
    margin-bottom: 20px;
  }
  .yibuhuo_title{
    padding-top: 20px;
    border-top: 1px solid #999;
    margin-top: 20px;
  }
  .pd20{
    padding-bottom: 20px;
  }
</style>
