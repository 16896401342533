var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.dialog_btnClose },
        },
        [
          _c("div", { staticClass: "huobu_title" }, [_vm._v("货补池")]),
          _c(
            "el-form",
            { ref: "form", attrs: { inline: "", model: _vm.form } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { md: 5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "goodsProductName" } },
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "商品名称" },
                            model: {
                              value: _vm.form.goodsProductName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "goodsProductName", $$v)
                              },
                              expression: "form.goodsProductName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { md: 5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "goodsProductCode" } },
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "商品编码" },
                            model: {
                              value: _vm.form.goodsProductCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "goodsProductCode", $$v)
                              },
                              expression: "form.goodsProductCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { md: 4 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.search },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.reset("form")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "md20",
              attrs: { type: "primary" },
              on: { click: _vm.add },
            },
            [_vm._v("批量添加")]
          ),
          _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                "row-id": "id",
                "tree-config": { children: "productList", expandAll: true },
                "edit-config": { trigger: "click", mode: "cell" },
                data: _vm.tableData,
              },
            },
            [
              _c("vxe-table-column", {
                attrs: {
                  field: "goodsProductCode",
                  title: "货补商品编码",
                  "tree-node": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: { field: "usableAmount", title: "可用余额（元）" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "goodsProductName", title: "货补商品" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "spec", title: "规格" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "saleUnitName", title: "单位" },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "productNum",
                  title: "选择数量",
                  "edit-render": {
                    name: "$input",
                    events: { change: _vm.ChangeNumEvent },
                  },
                },
              }),
              _c("vxe-table-column", {
                attrs: { field: "useAmount", title: "本单已选金额" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "huobu_title yibuhuo_title" }, [
            _vm._v("已选货补商品"),
          ]),
          _c(
            "el-button",
            {
              staticClass: "md20",
              attrs: { type: "primary" },
              on: { click: _vm.tableDel },
            },
            [_vm._v("批量删除")]
          ),
          _c(
            "vxe-table",
            {
              ref: "xCheckTable",
              staticClass: "md20",
              attrs: {
                "row-id": "id",
                "edit-config": { trigger: "click", mode: "cell" },
                data: _vm.checkTableData,
              },
              on: {
                "checkbox-all": function (ref) {
                  var records = ref.records

                  return _vm.selectAllEvent({ records: records })
                },
                "checkbox-change": function (ref) {
                  var records = ref.records

                  return _vm.selectChangeEvent({ records: records })
                },
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { type: "checkbox", width: "50" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "goodsProductCode", title: "商品编码" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "goodsProductName", title: "商品名称" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "spec", title: "规格" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "saleUnitName", title: "单位" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "productNum", title: "数量" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "useAmount", title: "金额" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "md20" }, [
            _vm._v("本单货补可用金额（元）：" + _vm._s(_vm.usableAmount)),
          ]),
          _c("div", { staticClass: "pd20" }, [
            _vm._v("本单货补已用金额（元）：" + _vm._s(_vm.useAmount)),
          ]),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.dialog_btnConfirm },
                },
                [_vm._v("提交 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.dialog_btnClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }