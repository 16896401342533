<!--
 * @Description: table组件
 * @Autor: txp
 * @Date: 2021-04-22 10:14:52
-->
<template>
  <div class="tableconent">
    <el-table ref="tableconentId"
              :data="table_config.table_data"
              :header-cell-style="{textAlign: 'center',fontSize:'13px',color:'#787878',background:'#F5F5F5'}"
              :cell-style="{textAlign:'center',fontSize:'14px',color:'#1D1D1D',lineHeight:'24px'}"
              :border="table_config.isBorder"
              :span-method="objectSpanMethod"
              @selection-change="handleSelectionChange">
      <el-table-column label="全选"
                       type="selection"
                       v-if="table_config.checkbox">
      </el-table-column>
      <el-table-column v-for="(item,index) in table_config.thead"
                       :key="index"
                       :prop="item.prop"
                       :label="item.label">
        <template slot-scope="scope">
          <div v-if="item.columnKey === 'rule'">
            <div v-for="(rule,k) in scope.row.ruleInfo" :key="k">
              {{rule}}
            </div>
          </div>
          <div v-else>
            {{scope.row[item.prop]}}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'tableconent',
  props: {
    table_config: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      theme: 'theme',
    }),
  },
  methods: {
    objectSpanMethod({
      row, column, rowIndex, columnIndex,
    }) {
      if (column.property === 'totalProductOrderQty') {
        if (rowIndex === 0) {
          return {
            rowspan: this.table_config.table_data.length,
            colspan: 1,
          };
        }
        return {
          rowspan: 0,
          colspan: 1,
        };
      }
    },
    /**
     * @description: 去下单，通过id，调用接口
     * @param {*}
     * @return {*}
     * @author: txp
     */
    handleSelectionChange(val) {
      console.log('查看选中得值');
    },
  },
};
</script>
